<mat-card class="distanza">
  <button
    mat-stroked-button
    color="warn"
    class="pulsante_new"
    (click)="editManufacturer(null)"
  >
    New Manufacturer
  </button>
</mat-card>
<app-manufacturerformform></app-manufacturerformform>
<mat-card class="distanza">
  <mat-card-title>ACTIVE MANUFACTURER LIST : </mat-card-title>

  <mat-card-content
    class="cella example-card mat-elevation-z6"
    *ngFor="let manufacturer of manufacturers"
  >
    <div class="row">
      <div class="column_sx">
        <i>ID : </i> {{ manufacturer._id }} <br />
        <i>Name :</i> {{ manufacturer.basic_info.name }} <br />
        <i>Address :</i> {{ manufacturer.basic_info.address }} <br />
      </div>
      <div class="column_center">
        <i>Email : </i> {{ manufacturer.basic_info.email }} <br />
        <i>Phone :</i> {{ manufacturer.basic_info.phone }} <br />
        <i>Website :</i> {{ manufacturer.basic_info.website }} <br />
      </div>
        <div class="column_dx">
          <div class="row">
            <div class="column">
              <button
                *ngIf="manufacturer.basic_info.logo"
                mat-icon-button
                color="warn"
                class="distanza4"
                (click)="showimage(manufacturer.basic_info.logo)"
              >
                <mat-icon>image</mat-icon>
              </button>
            </div>
            <div class="column">
              <button
                mat-icon-button
                color="warn"
                class="distanza5"
                (click)="editManufacturer(manufacturer)"
              >
                <mat-icon>settings</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

  </mat-card-content>
</mat-card>
