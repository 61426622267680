<mat-card class="distanza">
  <mat-card-title>SELECT MANUFACTURER : </mat-card-title>
<mat-radio-group aria-label="Select an option">
  <mat-card-content
    class="cella  scrollable-content"

  >
  <div class="cella3 example-card mat-elevation-z6" *ngFor="let manufacturer of manufacturers"
    (click)="onSelect(manufacturer)">
    <div class="row">
      <div class="column_up_sx">
        <div class="row">
          <div class="column_sx">
            <i>ID : </i> {{ manufacturer._id }} <br />
            <i>Name :</i> {{ manufacturer.basic_info.name }} <br />
            <i>Address :</i> {{ manufacturer.basic_info.address }} <br />
          </div>
          <div class="column_sx">
            <i>Email : </i> {{ manufacturer.basic_info.email }} <br />
            <i>Phone :</i> {{ manufacturer.basic_info.phone }} <br />
            <i>Website :</i> {{ manufacturer.basic_info.website }} <br />
          </div>
        </div>
      </div>
      <div class="column_up_dx">
         <mat-radio-button  [value]="manufacturer._id" [checked]="data._id == manufacturer._id"> </mat-radio-button>
      </div>
    </div>
    </div>
  </mat-card-content>
</mat-radio-group>
</mat-card>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Back!</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok !</button>
</div>
