<div class="custom-dialog {{modalType}}">
	<h2 mat-dialog-title>{{modalTitle}}</h2>
	<mat-dialog-content>
	 <img [src]="logo" />

	</mat-dialog-content>
	<mat-dialog-actions align="end">

		<button mat-raised-button color="warn" [mat-dialog-close]="true">OK</button>
	</mat-dialog-actions>
</div>
