import { i_Lab } from '../models/Lab';
export class SelectedLab {

  private lab: i_Lab;


  private selected: boolean;

  constructor($lab: i_Lab, $selected: boolean) {
    this.lab = $lab;
    this.selected = $selected;
  }

    /**
     * Getter $lab
     * @return {i_Lab}
     */
	public get $lab(): i_Lab {
		return this.lab;
	}

    /**
     * Getter $selected
     * @return {boolean}
     */
	public get $selected(): boolean {
		return this.selected;
	}

    /**
     * Setter $lab
     * @param {i_Lab} value
     */
	public set $lab(value: i_Lab) {
		this.lab = value;
	}

    /**
     * Setter $selected
     * @param {boolean} value
     */
	public set $selected(value: boolean) {
		this.selected = value;
	}

}
