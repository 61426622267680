import { Component, Inject, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { KcConnectService } from '../providers/kc-connect.service';
import { logged } from '../support/logged';

import { IntecomService } from '../providers/intecom.service';
import { Subscription } from 'rxjs';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { i_Manufacturer, Manufacturer } from '../models/Manufacturer';
import { ManufacturerProviderService } from '../providers/manufacturer-provider.service';

@Component({
  selector: 'app-manuifacturerselect',
  templateUrl: './manuifacturerselect.component.html',
  styleUrls: ['./manuifacturerselect.component.scss']
})
export class ManuifacturerselectComponent extends logged implements OnInit {

  subscription: Subscription;
  env: any;
  manufacturers: i_Manufacturer[] = [];


  constructor(protected keycloakService: KeycloakService,
    protected comunication: KcConnectService,
    protected manufacturer_service: ManufacturerProviderService,
    private intercom: IntecomService,
    public dialogRef: MatDialogRef<ManuifacturerselectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: i_Manufacturer
  ) {
    super(keycloakService, comunication);
    this.env = environment;

  }



  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit() {
    console.log("Mi tiro su!")
    this.populatelist();

  }



  ngOnDestroy() {
    console.log("Mi spengo")
  }

  populatelist() {
    this.manufacturer_service.getManufacturers().then((value: any) => {
      console.log("SUCCESSO: ", value)
      this.manufacturers = value as i_Manufacturer[];
    }).catch((e) => {
      console.log("ERROR: ", e)
    })
  }


  logout() {
    this.keycloakService.logout();
  }


  onSelect(manufacturer: i_Manufacturer) {

    console.log("---*A arrivato ", manufacturer)
    this.data = manufacturer;
    console.log("---*A  this.data  ", this.data )

  }

}

