<div class="custom-dialog {{modalType}}">
	<h2 mat-dialog-title>{{modalTitle}}</h2>
	<mat-dialog-content>
		<ngx-qrcode
  [elementType]="elementType"
  [errorCorrectionLevel]="correctionLevel"
  [value]="value"
  class="bshadow"></ngx-qrcode>
  <textarea  class="textarea1" [(ngModel)] = "value"></textarea>
	</mat-dialog-content>
	<mat-dialog-actions align="end">

		<button mat-raised-button color="warn" [mat-dialog-close]="true">OK</button>
	</mat-dialog-actions>
</div>
