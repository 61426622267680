import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';

import { KeycloakService } from 'keycloak-angular';

import { environment } from '../../environments/environment';
import { KcConnectService } from '../providers/kc-connect.service';
import { logged } from '../support/logged';
import { UserProviderService } from '../providers/user-provider.service';
import { KeyUser } from '../models/keycloakuser';

@Component({
  selector: 'logged-in',
  templateUrl: './loggedin.page.html',
  styleUrls: ['./loggedin.page.css']
})
export class LoggedinPage extends logged implements OnInit {

  result: any = {};
  env: any;
  users: KeyUser[] = [];



  constructor(protected keycloakService: KeycloakService, protected comunication: KcConnectService, protected user_service: UserProviderService) {
    super(keycloakService, comunication);
    this.env = environment;

  }
  ngOnInit(): void {
    this.populatelist();
  }

  populatelist () {
    this.user_service.getUsers().then((value: any) => {
      console.log("SUCCESSO: ", value)
      this.users = value as KeyUser[];
    }).catch((e) => {
      console.log("ERROR: ",e)
    })
  }

  logout() {
    this.keycloakService.logout();
  }
  goToUser() {
    window.open(environment.create_user_link);
  }

}

