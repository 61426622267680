import { SelectControlValueAccessor } from '@angular/forms';
import { KeycloakConfig } from 'keycloak-angular';

// Add here your keycloak setup infos

export const environment = {

  production: false,
  envName: 'local',
  keycloak: {
    issuer: 'https://auth.s3qr.it/auth',
    realm: 'S3qr',
    requireHttps: true,
    clientId: 's3qr-manager'
  },
  main_url: 'https://www.fhffcapp.it/s3edgemanager',
  // main_url: 'http://127.0.0.1:5021',
  getUsers_url: "/getUsers",
  getLabs_url: "/getLabs",
  addLab_url: "/addLab",
  addManufacturer: "/addManufacturer",
  getManufacturers: "/getManufacturers",
  addLot4Analysis: "/addLot4Analysis",
  getLot4Analysis: "/getLot4Analysis",
  title: "S3QR.IT MANAGER MODULE",
  bottom: "© Work in progress CNR-IREA 2020 - v.0.0.1b",
  role: "Manager",
  timeout: 10000,
  refreshlablist: "REFRESH_LAB_LIST",
  success: "Success!",
  error: "Error!",
  warning: "Warning!",
  urlqr: "https://www.s3qr.it/home/",
  create_user_link: "https://auth.s3qr.it/auth/admin/master/console/#/realms/S3qr/users",
  backend_getPendingLot4AnalysisByLab: "/getPendingLot4AnalysisByLab/",
  backend_getCompletedLot4AnalysisByLab: "/getPendingLot4AnalysisByLab/",
  backend_deleteLot4AnalysisById: "/deleteLot4AnalysisById/",
  update_Lot: "/addGenericRepo",


};
