import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { catchError, timeout } from 'rxjs/operators';

import { throwError } from 'rxjs/internal/observable/throwError';
import { Lab } from '../models/Lab';
@Injectable({
  providedIn: 'root'
})
export class LabProviderService {

  constructor(private http: HttpClient, protected keycloakService: KeycloakService) { }

  public async getLabs(): Promise<any> {

    return this.http.get(environment.main_url + environment.getLabs_url).pipe(timeout(environment.timeout)).toPromise();

  }


  public async addLab(l: Lab): Promise<any> {
    console.log(l)

    return   this.http.post(environment.main_url + environment.addLab_url,l).toPromise();

  }

}
