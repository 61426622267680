<mat-card class="distanza">
  <mat-card-title>INSERT NEW LAB : </mat-card-title>
  <mat-card-content>
    <form [formGroup]="registerForm" (ngSubmit)="addlab()">
      <div class="row">
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Laboratory Name</mat-label>
            <input
              matInput
              formControlName="name"
              [(ngModel)]="lab.basic_info.name"
              placeholder="Laboratory Name"
            />
             <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Name is required</div>
                    </div>
          </mat-form-field>
        </div>
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Laboratory ID</mat-label>
            <input
              matInput
              formControlName="_id"
              [(ngModel)]="lab._id"
              placeholder="Laboratory ID"
            />
             <div *ngIf="submitted && f._id.errors" class="invalid-feedback">
                        <div *ngIf="f._id.errors.required">ID is required</div>
                    </div>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Laboratory Email</mat-label>
            <input
              matInput
              formControlName="email"
              [(ngModel)]="lab.basic_info.email"
              placeholder="Laboratory Email"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">
                Email must be a valid email address
              </div>
            </div>
          </mat-form-field>
        </div>
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Laboratory Phone</mat-label>
            <input
              matInput
              formControlName="phone"
              [(ngModel)]="lab.basic_info.phone"
              placeholder="Laboratory Phone"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Laboratory Address</mat-label>
            <input
              matInput
              formControlName="address"
              [(ngModel)]="lab.basic_info.address"
              placeholder="Laboratory Address"
            />
            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                        <div *ngIf="f.address.errors.required">Address is required</div>
                    </div>
          </mat-form-field>
        </div>
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Laboratory Website Url</mat-label>
            <input
              matInput
              formControlName="website"
              [(ngModel)]="lab.basic_info.website"
              placeholder="Laboratory Website Url"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="forminput2">
          <mat-label>Laboratory Description</mat-label>
          <textarea
            rows="4"
            matInput
            formControlName="desc"
            [(ngModel)]="lab.desc"
            placeholder="Laboratory descripion, insert what you want."
          ></textarea>
        </mat-form-field>
      </div>
     <div class="row">
        <div class="forminput">
          <mat-label  *ngIf="!isEdit" >Upload Lab logo</mat-label>
           <mat-label  *ngIf="isEdit" >Edit Uploading a new Lab logo</mat-label><br>
          <input
            type="file"
            (change)="getLogo($event)"
            id="filesLogo"
            #filesLogo
            accept="image/*"
          />
        </div>
      </div>

      <button *ngIf="!isEdit" mat-raised-button color="warn" class="distanza2">Add Lab</button>
      <button *ngIf="isEdit" mat-raised-button color="warn" class="distanza2">Edit Lab</button>
    </form>
  </mat-card-content>
  <!-- <button mat-raised-button class="distanza2" (click)="test()">
            TEST
          </button> -->
</mat-card>
