<mat-card class="distanza">
  <mat-card-title>CREATE A NEW LOT : </mat-card-title>
  <mat-card-content>
    <div class="row">
      <div class="column_dx">
        <button mat-stroked-button class="distanza5" (click)="selectLab()">
          SELECT LABs
        </button>
      </div>
      <div class="column_dx">
        <mat-label class="distanza5">Selected Labs : </mat-label>
        <mat-label class="distanza5">{{ labs_string }}</mat-label>

        <div *ngIf="submitted && f.labs.errors" class="invalid-feedback">
          <div *ngIf="f.labs.errors.required" class="distanza5 error">
            Lab is required
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column_dx">
        <button
          mat-stroked-button
          class="distanza5"
          (click)="selctManufacturer()"
        >
          SELECT MANUFACTURER
        </button>
      </div>
      <div class="column_dx">
        <mat-label class="distanza5">Selected Manufacturer : </mat-label>
        <mat-label class="distanza5">{{ manufacturer_string }}</mat-label>

        <div
          *ngIf="submitted && f.manufacturer.errors"
          class="invalid-feedback"
        >
          <div
            *ngIf="f.manufacturer.errors.required && !lot4Analysis.manufacturer"
            class="distanza5 error"
          >
            Manufacturer is required
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="registerForm" (ngSubmit)="addlot()">
      <div class="row">
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>LOT Identifier</mat-label>
            <input
              matInput
              formControlName="lot"
              [(ngModel)]="lot4Analysis.lot"
              placeholder="LOT Identifier"
            />
            <div *ngIf="submitted && f.lot.errors" class="invalid-feedback">
              <div *ngIf="f.lot.errors.required">
                LOT Identifier is required
              </div>
            </div>
          </mat-form-field>
        </div>
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Category</mat-label>
            <input
              matInput
              formControlName="category"
              [(ngModel)]="lot4Analysis.category"
              placeholder="Category"
            />
            <div
              *ngIf="submitted && f.category.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.category.errors.required">ID is required</div>
            </div>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <mat-label class="forminput">Lot Description</mat-label>
        <div class="editor">
          <ejs-richtexteditor
            formControlName="description"
            required
            [toolbarSettings]="tools"
            [(ngModel)]="lot4Analysis.desc"
            placeholder="Lot Description"
          ></ejs-richtexteditor>
        </div>


      </div>

      <div class="row">
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Creation Date (Optional) </mat-label>
            <input
              matInput
              formControlName="creation_date"
              [matDatepicker]="picker1"
              [(ngModel)]="lot4Analysis.creation_date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="column_dx">
          <mat-form-field appearance="standard" class="forminput">
            <mat-label>Expiration Date</mat-label>
            <input
              matInput
              formControlName="expiration_date"
              [matDatepicker]="picker2"
              [(ngModel)]="lot4Analysis.expiration_date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="row">
          <div class="forminput">
          <mat-label  *ngIf="isEdit" >Edit Uploading a new Lot Image (optional)</mat-label><br>
          <mat-label *ngIf="!isEdit"  >Upload Lot logo (optional)</mat-label><br>
            <input
              type="file"
              (change)="getLogo($event)"
              id="filesLogo"
              #filesLogo
              accept="image/*"
            />
          </div>
        </div>
      </div>
      <button *ngIf="!isEdit" mat-raised-button color="warn" class="distanza2">Add Lot</button>
       <button *ngIf="isEdit" mat-raised-button color="warn" class="distanza2">Edit Lot</button>
      <div *ngIf="submitted && f.expiration_date.errors">
        <div *ngIf="f.expiration_date.errors.range">
          Creation Date should be before Expiration Date!!!
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
