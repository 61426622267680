<mat-card class="distanza">
  <mat-card-title>SELECT LAB (1 OR MORE) : </mat-card-title>
  <mat-card-content class="cella scrollable-content">
    <div
      class="cella3 example-card mat-elevation-z6"
      *ngFor="let lab of labs"
      (click)="onSelect(lab)"
    >
      <div class="row">
        <div class="column_up_sx">
          <div class="row">
            <div class="column_sx">
              <i>ID : </i> {{ lab.$lab._id }} <br />
              <i>Name :</i> {{ lab.$lab.basic_info.name }} <br />
              <i>Address :</i> {{ lab.$lab.basic_info.address }} <br />
            </div>
            <div class="column_sx"></div>
            <i>Email : </i> {{ lab.$lab.basic_info.email }} <br />
            <i>Phone :</i> {{ lab.$lab.basic_info.phone }} <br />
            <i>Website :</i> {{ lab.$lab.basic_info.website }} <br />
          </div>
          <div class="row">
            <i>Description :</i> <br />
            {{ lab.$lab.desc }}
          </div>
        </div>
        <div class="column_up_dx">
          <mat-checkbox [(ngModel)]="lab.$selected" color="warn"  [disabled]="true"  ></mat-checkbox>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Back!</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok !</button>
</div>
