import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';

import { KeycloakService } from 'keycloak-angular';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { KcConnectService } from '../providers/kc-connect.service';
import { logged } from '../support/logged';
import { UserProviderService } from '../providers/user-provider.service';
import { KeyUser } from '../models/keycloakuser';
import { LabProviderService } from '../providers/lab-provider.service';
import { i_Lab } from '../models/Lab';
import { IntecomService } from '../providers/intecom.service';
import { i_Manufacturer } from '../../../../s3qr-bdmanager/models/Manufacturer';
import { ManufacturerProviderService } from '../providers/manufacturer-provider.service';
import { Subscription } from 'rxjs';
import { LotviewComponent } from '../lotview/lotview.component';
import { ShowimageComponent } from '../showimage/showimage.component';
@Component({
  selector: 'app-manufacturerview',
  templateUrl: './manufacturerview.component.html',
  styleUrls: ['./manufacturerview.component.css']
})
export class ManufacturerviewComponent extends logged implements OnInit {
  subscription: Subscription;
  env: any;
  manufacturers: i_Manufacturer[] = [];


  constructor(public dialog: MatDialog,
    protected keycloakService: KeycloakService,
    protected comunication: KcConnectService,
    protected manufacturer_service: ManufacturerProviderService,
    private intercom: IntecomService) {
    super(keycloakService, comunication);
    this.env = environment;

  }

  ngOnInit() {
    this.populatelist();
    this.subscription =this.intercom.item$.subscribe((message) => {
      this.populatelist();
    });
  }

  receiveUpdate($event) {
    console.log("Evento", $event)
    if ($event == this.env.refreshlablist) {
      this.populatelist();
    }

  }

  ngOnDestroy() {
    console.log("Mi spengo")
    this.subscription.unsubscribe()
  }

  populatelist() {
    this.manufacturer_service.getManufacturers().then((value: any) => {
      console.log("SUCCESSO: ", value)
      this.manufacturers = value as i_Manufacturer[];
    }).catch((e) => {
      console.log("ERROR: ", e)
    })
  }

  logout() {
    this.keycloakService.logout();
  }
  editManufacturer(man: i_Manufacturer) {
    this.intercom.refreshManufactureForm(man);
  }

  showimage(logo: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    dialogConfig.data = logo
    this.dialog.open(ShowimageComponent, dialogConfig);
  }
}
