<mat-card class="distanza">
  <button
    mat-stroked-button
    color="warn"
    class="pulsante_new"
    (click)="editlab(null)"
  >
    New Lab
  </button>
</mat-card>
<app-labform></app-labform>
<mat-card class="distanza">
  <!-- <div class="distanza">

</div> -->
  <mat-card-title>ACTIVE LAB LIST : </mat-card-title>

  <mat-card-content
    class="cella example-card mat-elevation-z6"
    *ngFor="let lab of labs"
    (click)="onSelect(lab)"
  >
    <div class="row">
      <div class="column_sx">
        <i>ID : </i> {{ lab._id }} <br />
        <i>Name :</i> {{ lab.basic_info.name }} <br />
        <i>Address :</i> {{ lab.basic_info.address }} <br />
      </div>
      <div class="column_center">
        <i>Email : </i> {{ lab.basic_info.email }} <br />
        <i>Phone :</i> {{ lab.basic_info.phone }} <br />
        <i>Website :</i> {{ lab.basic_info.website }} <br />
      </div>
      <div class="column_dx">
        <div class="row">
          <div class="column">
            <button
              *ngIf="lab.basic_info.logo"
              mat-icon-button
              color="warn"
              class="distanza5"
              matTooltip="Show Lab Logo"
              (click)="showimage(lab.basic_info.logo)"
            >
              <mat-icon>image</mat-icon>
            </button>
          </div>
          <div class="column">
            <button
              mat-icon-button
              color="warn"
              class="distanza5"
              matTooltip="Edit Lab settings"
              (click)="editlab(lab)"
            >
              <mat-icon>settings</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <i>Description :</i> <br />
      {{ lab.desc }}
    </div>
  </mat-card-content>
</mat-card>
