import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';

import { KeycloakService } from 'keycloak-angular';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { KcConnectService } from '../providers/kc-connect.service';
import { logged } from '../support/logged';
import { UserProviderService } from '../providers/user-provider.service';
import { KeyUser } from '../models/keycloakuser';
import { LabProviderService } from '../providers/lab-provider.service';
import { i_Lab } from '../models/Lab';
import { IntecomService } from '../providers/intecom.service';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs';
import { ShowimageComponent } from '../showimage/showimage.component';
@Component({
  selector: 'app-labview',
  templateUrl: './labview.component.html',
  styleUrls: ['./labview.component.css']
})
export class LabviewComponent extends logged implements OnInit {

  subscription: Subscription;
  env: any;
  labs: i_Lab[] = [];


  constructor(public dialog: MatDialog,
    protected keycloakService: KeycloakService,
    protected comunication: KcConnectService,
    protected lab_service: LabProviderService,
    private intercom: IntecomService) {
    super(keycloakService, comunication);
    this.env = environment;

  }

  ngOnInit() {
    console.log("Mi tiro su!")
    this.populatelist();
    this.subscription = this.intercom.item$.subscribe((message) => {
      this.populatelist();
    });
  }

  receiveUpdate($event) {
    console.log("Evento",$event)
    if ($event == this.env.refreshlablist) {
      this.populatelist();
    }

  }

  ngOnDestroy() {
    console.log("Mi spengo")
    this.subscription.unsubscribe()
  }

  populatelist() {
    this.lab_service.getLabs().then((value: any) => {
      console.log("SUCCESSO: ", value)
      this.labs = value as i_Lab[];
    }).catch((e) => {
      console.log("ERROR: ", e)
    })
  }

  logout() {
    this.keycloakService.logout();
  }

  editlab(lab: i_Lab) {
    this.intercom.refreshLabForm(lab);
  }
  onSelect(lab : i_Lab) {
    console.log(lab)
  }
  showimage(logo: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    dialogConfig.data = logo
    this.dialog.open(ShowimageComponent, dialogConfig);
  }

}
