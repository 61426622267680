import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { timeout } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
@Injectable({
  providedIn: 'root'
})
export class UserProviderService {

  constructor(private http: HttpClient, protected keycloakService: KeycloakService) { }


  public async getUsers(): Promise<any> {


      return this.http.get(environment.main_url + environment.getUsers_url).pipe(timeout(3000)).toPromise();

  }




}
