<mat-card class="distanza">
  <mat-card-title>ACTIVE USER LIST : </mat-card-title>

  <mat-card-content
    class="cella example-card mat-elevation-z6"
    *ngFor="let user of users"
  >
    <div class="row">
      <div class="column_sx">
        <i>User Name :</i> {{ user.username }} <br />
        <i>Full Name :</i> {{ user.firstName }} {{ user.lastName }}<br />
        <i>Email :</i> {{ user.email }}
      </div>
      <div class="column_dx">
        <i>Lab :</i> {{ user.attributes?.lab }} <br />
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="distanza">
  <mat-card-content class="cella example-card mat-elevation-z6">
     <button mat-raised-button color="warn" class="distanza2" (click)="goToUser()">Add User</button>
  </mat-card-content>
</mat-card>
<!--
<mat-card class="distanza">
  <mat-card-subtitle> Result : </mat-card-subtitle>
  <mat-card-content>
    <pre class="scritta">{{ result | json }}</pre>
  </mat-card-content>
</mat-card> -->

<mat-card class="distanza">
  <mat-card-subtitle> TOKEN FROM KEYCLOAK </mat-card-subtitle>
  <mat-card-content>
    <pre class="scritta">{{ token | json }}</pre>
  </mat-card-content>
</mat-card>
