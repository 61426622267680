<header>
  <mat-card class="distanza titolo">
    <mat-card-title>
      <div class="row">
        <div class="column_center">
          <img class="logo3" src="assets/img/logos3.png" />
        </div>
        <div class="column_sx">{{ env.title }}</div>
        <div class="column_dx">
          <button mat-raised-button class="distanza2" (click)="logout()">
            LOGOUT
          </button>
        </div>
      </div>
    </mat-card-title>
    <mat-card-subtitle class="subtitle">
      Welcome : {{ name }}, you have been logged in!
    </mat-card-subtitle>
  </mat-card>
</header>
<div id="comp-render" *ngIf="display">
  <mat-card class="distanza coloremenu ">
    <mat-card-content>
      <div class="row">
        <div class="menu_all">
          <button  mat-stroked-button color="warn" class="distanza2 colorebottonimenu" (click)="goToUsers()">
            USERS
          </button>
        </div>
        <div class="menu_all">
          <button  mat-stroked-button color="warn" class="distanza2 colorebottonimenu"  (click)="goToLab()">
            LABS
          </button>
        </div>
        <div class="menu_all">
          <button  mat-stroked-button color="warn" class="distanza2 colorebottonimenu"     (click)="goToManufacturer()">
            MANUFACTURERS
          </button>
        </div>
        <div class="menu_all">
          <button mat-stroked-button color="warn" class="distanza2 colorebottonimenu"  (click)="goToLots()">
            LOTS
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div id="comp-render" *ngIf="_users">
    <logged-in></logged-in>
  </div>
  <div id="comp-render" *ngIf="_labs">
    <app-labview></app-labview>
  </div>
  <div id="comp-render" *ngIf="_manufacturer">
    <app-manufacturerview></app-manufacturerview>
  </div>
<div id="comp-render" *ngIf="_lots">
    <app-lotview></app-lotview>
  </div>
</div>

<footer>
  <mat-card class="distanza titolo">
    <mat-card-content>
      <p>{{ env.bottom }}</p>
    </mat-card-content>
  </mat-card>
</footer>
