import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { i_Lab } from '../models/Lab';
import { i_Lot4Analysis } from '../models/Lot4Analysis';
import { i_Manufacturer } from './../../app/models/Manufacturer';

@Injectable({
  providedIn: 'root'
})
export class IntecomService {

  constructor() { }
  // private _refresh_Form: BehaviorSubject<string> = new BehaviorSubject(null);
  private _refresh_Form_Lot: BehaviorSubject<string> = new BehaviorSubject(null);
  private _refresh_Form_Manufacture: BehaviorSubject<string> = new BehaviorSubject(null);
  private _refresh_Form_Lab: BehaviorSubject<string> = new BehaviorSubject(null);
  private _refresh_message: BehaviorSubject<string> = new BehaviorSubject(null);

  formLotItem$ = this._refresh_Form_Lot.asObservable();
  formManufactureItem$ = this._refresh_Form_Manufacture.asObservable();
  formLabItem$ = this._refresh_Form_Lab.asObservable();
  item$ = this._refresh_message.asObservable();

  refreshList(message: string) {
    this._refresh_message.next(message);
  }
  refreshLabForm(i_lab: i_Lab) {
    this._refresh_Form_Lab.next(JSON.stringify(i_lab));
  }

  refreshManufactureForm(i_man: i_Manufacturer) {
    this._refresh_Form_Manufacture.next(JSON.stringify(i_man));
  }

  refreshLotForm(i_lot: i_Lot4Analysis ) {
    this._refresh_Form_Lot.next(JSON.stringify(i_lot));
  }


}
