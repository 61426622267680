import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';

import { KeycloakService } from 'keycloak-angular';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { KcConnectService } from '../providers/kc-connect.service';
import { logged } from '../support/logged';

import { IntecomService } from '../providers/intecom.service';

import { Subscription } from 'rxjs';
import { i_Lot4Analysis, i_Status, Lot4Analysis } from '../models/Lot4Analysis';
import { LotProviderService } from '../providers/lot-provider.service';
import { QrcodegeneratorComponent } from '../qrcodegenerator/qrcodegenerator.component';
import { ShowimageComponent } from '../showimage/showimage.component';
import { Generic_messageComponent } from '../generic_message/generic_message.component';

import { StatusComponetComponent } from '../status-componet/status-componet.component';
import { NGXLogger } from "ngx-logger";
@Component({
  selector: 'app-lotview',
  templateUrl: './lotview.component.html',
  styleUrls: ['./lotview.component.scss']
})
export class LotviewComponent extends logged implements OnInit {

  subscription: Subscription;
  env: any;
  lots: Lot4Analysis[] = [];
  pageOfItems: Array<any>;
  p: number = 1;

  constructor(public dialog: MatDialog,
    protected keycloakService: KeycloakService,
    protected comunication: KcConnectService,
    protected lot_service: LotProviderService,
    private intercom: IntecomService,
    private logger: NGXLogger) {
    super(keycloakService, comunication);
    this.env = environment;

  }
  ngOnInit() {
    console.log("Mi tiro su!")
    this.populatelist();
    this.subscription = this.intercom.item$.subscribe((message) => {
      this.populatelist();
    });
  }


  ngOnDestroy() {
    this.logger.info("Mi spengo")
    this.subscription.unsubscribe()
  }
  populatelist() {
    this.lot_service.getLot4Analysis().then((value: any) => {
      this.logger.info("[populatelist] - Success! : ", value)
      value = value as i_Lot4Analysis[];
      this.lots = [];
      value.forEach(element => {
        this.lots.push(Lot4Analysis.of(element))
      });
      this.logger.info("[populatelist] - lots" ,  this.lots)

    }).catch((e) => {
      this.logger.error("[populatelist]",e)
    })
  }

  logout() {
    this.keycloakService.logout();
  }

  testQR(id : string ) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    dialogConfig.data = environment.urlqr + id
    this.dialog.open(QrcodegeneratorComponent, dialogConfig);

  }

  showimage(logo: string) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    dialogConfig.data = logo
    this.dialog.open(ShowimageComponent, dialogConfig);
  }
  getStatus(status: i_Status[]) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    dialogConfig.data = status;
    this.dialog.open(StatusComponetComponent, dialogConfig);
  }
  editLot(lot: i_Lot4Analysis) {
    this.logger.info("[editLot] ", lot)
    this.intercom.refreshLotForm(lot)
  }

  deletelot(lot: i_Lot4Analysis) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;

    let _message: string =  "Would you like to delete this Lot ? "

    dialogConfig.data = { title: "Lot:  " + lot.lot + "", message: _message }
    const dialogRef2 = this.dialog.open(Generic_messageComponent, dialogConfig);

    dialogRef2.afterClosed().subscribe(result => {
      this.logger.info("[deletelot] The dialog was closed", result);

      if (result) {
        lot.rate_enabled = !lot.rate_enabled;
        this.lot_service.deleteLot4Analysis(lot).then((esito) => {
          if (esito) {
            this.populatelist();
          } else {
            this.logger.error("[deletelot] ", esito)
          }
        }).catch(e => {
          this.logger.error("[deletelot] ", e)
        })
      }

    });

  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  changeRating(lot: Lot4Analysis) {

    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;

    let _message: string = "";
    if (lot.rate_enabled) {
      _message = "Would you like to disable Rating ?"
    } else {
      _message = "Would you like to enable Rating ?"
    }
    dialogConfig.data ={ title : "Lot:  " + lot.lot + "", message : _message}
    const dialogRef2 = this.dialog.open(Generic_messageComponent, dialogConfig);


    dialogRef2.afterClosed().subscribe(result => {
      this.logger.info("[changeRating] The dialog was closed", result);

      if (result) {
        lot.rate_enabled = !lot.rate_enabled;
        this.lot_service.updateLot4Analysis(lot).then((esito) => {
          if (esito) {
            this.populatelist();
          } else {
            this.logger.error("[changeRating]", esito)
          }
        }).catch(e => {
          this.logger.error("[changeRating]", e)
        })
      }

    });


  }

}
