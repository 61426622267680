import { MatTooltipModule } from '@angular/material/tooltip';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { BrowserModule } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { CommonModule } from '@angular/common';
import { initializer } from './secure/kc-initializer';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClientModule } from '@angular/common/http';
import { LoggedinPage } from './loggedin/loggedin.page';
import { LabviewComponent } from './labview/labview.component';
import { LabformComponent } from './labform/labform.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ManufacturerformComponent } from './manufacturerform/manufacturerform.component';
import { ManufacturerviewComponent } from './manufacturerview/manufacturerview.component';
import { LabselectComponent } from './labselect/labselect.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { LotformComponent } from './lotform/lotform.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { ManuifacturerselectComponent } from './manuifacturerselect/manuifacturerselect.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { Generic_messageComponent } from './generic_message/generic_message.component';
import { QrcodegeneratorComponent } from './qrcodegenerator/qrcodegenerator.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { LotviewComponent } from './lotview/lotview.component';
import { ShowimageComponent } from './showimage/showimage.component';
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';

import { StatusComponetComponent } from './status-componet/status-componet.component';

import { NgxPaginationModule } from 'ngx-pagination';
@NgModule({
  declarations: [
    AppComponent,

    MainComponent,
    LoggedinPage,
    LabviewComponent,
    LabformComponent,
    ManufacturerformComponent,
    ManufacturerviewComponent,
    LabselectComponent,
    LotformComponent,
    ManuifacturerselectComponent,
    Generic_messageComponent,
    QrcodegeneratorComponent,
    LotviewComponent,
    ShowimageComponent,

    StatusComponetComponent,

  ],
  imports: [
    LoggerModule.forRoot({   level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.DEBUG }),
    CommonModule,
    BrowserModule,
    NgxPaginationModule,
    AppRoutingModule,
    MatCardModule,
    FormsModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatCheckboxModule,
    KeycloakAngularModule,
    MatIconModule,
    MatRadioModule,
    MatButtonModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDatepickerModule,
    NgxQRCodeModule,
    RichTextEditorAllModule,
    MatNativeDateModule,
    MatTooltipModule

  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
