import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ɵConsole } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LabselectComponent } from '../labselect/labselect.component';
import { i_Lab } from '../../app/models/Lab';
import { Lot4Analysis } from '../models/Lot4Analysis';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { IntecomService } from '../providers/intecom.service';
import { KcConnectService } from '../providers/kc-connect.service';
import { KeycloakService } from 'keycloak-angular';
import { ManuifacturerselectComponent } from '../manuifacturerselect/manuifacturerselect.component';
import { Manufacturer } from '../../app/models/Manufacturer';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../utils/DateFormat';
import { LotProviderService } from '../providers/lot-provider.service';
import { logged } from '../support/logged'
import { environment } from '../../environments/environment';
import { Generic_messageComponent } from '../generic_message/generic_message.component';
import { ThrowStmt } from '@angular/compiler';
import { QrcodegeneratorComponent } from '../qrcodegenerator/qrcodegenerator.component';
import { timingSafeEqual } from 'crypto';
import { ArrayValidators } from '../utils/ArrayValidators';

import { ToolbarService, LinkService, ImageService, HtmlEditorService, TableService } from '@syncfusion/ej2-angular-richtexteditor';
import { Subscription } from 'rxjs';



// const MyAwesomeRangeValidatorDateRange: ValidatorFn = (fg: FormGroup) => {
//   const start = fg.get('creation_date').value
//   const end = fg.get('expiration_date').value


//   console.log("-------------------------------------------------")
//   console.log("start ", start)
//   console.log("end ", end)

//   console.log("-------------------------------------------------")
//   return start !== null && end !== null && start < end
//     ? null
//     : { range: true };
// };
function MyAwesomeRangeValidatorDateRange() {

    return (formGroup: FormGroup) => {

      const start = formGroup.get('creation_date').value;
      const end = formGroup.get('expiration_date').value;
      const c_end = formGroup.get('expiration_date');
      console.log("-------------------------------------------------")
      console.log("start ", start)
      console.log("end ", end)

      console.log("-------------------------------------------------")

      if (c_end.errors) {
        console.error("QUI!!")
        return;
      }

      if (start < end) {
        console.log("--> giusto")
        c_end.setErrors(null);
      } else {
        console.error("QUI!!2")
        c_end.setErrors({ range: true });

      }
    };

  }



@Component({
  selector: 'app-lotform',
  templateUrl: './lotform.component.html',
  styleUrls: ['./lotform.component.scss'],
  providers: [
    ToolbarService, LinkService, ImageService, HtmlEditorService, TableService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class LotformComponent extends logged implements OnInit {
  env: any;
  registerForm: FormGroup;
  submitted = false;
  i_labs: i_Lab[] = []
  lot4Analysis: Lot4Analysis = new Lot4Analysis()
  labs_string: string = "";
  manufacturer_string: string = ""
  manufacturer: Manufacturer = new Manufacturer();

  subscription: Subscription;
  isEdit: boolean = false;


  @Output() refreshlistlabEvent = new EventEmitter<string>();
  @ViewChild("filesLogo") filesLogo: ElementRef;


  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'LowerCase', 'UpperCase', '|', 'Undo', 'Redo', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink', 'CreateTable',
      '|', 'FullScreen']
  };


  constructor(public dialog: MatDialog,
    protected keycloakService: KeycloakService,
    protected comunication: KcConnectService,
    private lotProviderService: LotProviderService,
    private formBuilder: FormBuilder,
    private intercom: IntecomService) {
    super(keycloakService, comunication);
    this.env = environment;
  }




  ngOnInit() {

    this.registerForm = this.formBuilder.group({

      lot: ['', Validators.required],
      category: ['', Validators.required],
      manufacturer: [],
      description: [],
      labs: [[], Validators.required],
      creation_date: [null, Validators.required],
      expiration_date: [null, Validators.required]
    }, { validator: MyAwesomeRangeValidatorDateRange() });

    this.subscription = this.intercom.formLotItem$.subscribe((message) => {
      try {
        this.lot4Analysis = Lot4Analysis.of(JSON.parse(message))
        console.log(this.lot4Analysis)
        this.isEdit = true;
        this.i_labs = this.lot4Analysis.labs;
        this.manufacturer_string = this.lot4Analysis.manufacturer.basic_info.name
        this.registerForm.patchValue({ labs: this.i_labs })
        this.getLabIdsString()
      } catch (e) {
        this.isEdit = false;
        this.lot4Analysis  = new Lot4Analysis()
        console.error(e)
      }

    });


  }

  selectLab() {
    const dialogRef = this.dialog.open(LabselectComponent, {
      width: '90%',
      data: this.i_labs
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.i_labs = result;

      this.registerForm.patchValue({ labs: this.i_labs })
      this.lot4Analysis.labs = result;
      this.getLabIdsString()
      console.log("-- arrivato  -->", this.i_labs)
    });

  }

  selctManufacturer() {
    const dialogRef2 = this.dialog.open(ManuifacturerselectComponent, {
      width: '90%',
      data: this.manufacturer
    });

    dialogRef2.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log("-- arrivato M  -->", result)
      this.lot4Analysis.manufacturer = result;
      this.manufacturer_string = this.lot4Analysis.manufacturer.basic_info.name
      console.log("-- arrivato M  -->", this.lot4Analysis)
    });

  }

  checkLab() {
    if (this.lot4Analysis.labs) {
      if (this.lot4Analysis.labs.length < 1) {
        return { lab: true }
      }
    } else {
      return { lab: true }
    }
    return null

  }

  addlot() {


    console.log("+++++++++++++++++++++++++++++++++++++++++++++++++")
    console.log(this.lot4Analysis)
    console.log(this.registerForm)
    console.log(this.checkLab())
    console.log("+++++++++++++++++++++++++++++++++++++++++++++++++")



    this.submitted = true;
    if (this.registerForm.invalid) {
      console.log("invalid")
      return;
    }


    if (this.isEdit) {
      this.lotProviderService.updateLot4Analysis(this.lot4Analysis).then((res: boolean) => {

        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = 400;
        if (res) {
          this.intercom.refreshList(this.env.refreshlablist);
          dialogConfig.data = {
            title: environment.success,
          };
          this.submitted = false;
          this.i_labs = []
          this.labs_string = "";
          this.lot4Analysis = new Lot4Analysis();
          this.manufacturer_string = ""
          this.isEdit = false;
        } else {
          dialogConfig.data = {
            title: environment.error,
          };
        }

        this.dialog.open(Generic_messageComponent, dialogConfig);

      }).catch((err) => {
        console.log(err)
      })

    } else {
      this.lotProviderService.addLot4Analysis(this.lot4Analysis).then((res: boolean) => {

        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = 400;
        if (res) {
          this.intercom.refreshList(this.env.refreshlablist);
          dialogConfig.data = {
            title: environment.success,
          };
          this.submitted = false;
          this.i_labs = []
          this.labs_string = "";
          this.lot4Analysis = new Lot4Analysis();
          this.manufacturer_string = ""
        } else {
          dialogConfig.data = {
            title: environment.error,
          };
        }

        this.dialog.open(Generic_messageComponent, dialogConfig);

      }).catch((err) => {
        console.log(err)
      })
    }
    console.log("-------------------------------------------------")
    console.log(this.lot4Analysis)
    console.log("-------------------------------------------------")
  }


  get f() { return this.registerForm.controls; }


  getLabIdsString() {
    this.labs_string = "";
    if (this.i_labs) {
      for (let i = 0; i < this.i_labs.length; i++) {
        if (i == 0) {
          this.labs_string = this.i_labs[i]._id;
        } else {
          this.labs_string = this.labs_string + ", " + this.i_labs[i]._id;
        }
      }
    }
  }

  testQR() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;
    dialogConfig.data = "https://s3qr.it/#/mhvXdrZT4jP5T8vBxuvm75"
    this.dialog.open(QrcodegeneratorComponent, dialogConfig);

  }

  onSelect(lab: i_Lab) {
    console.log(lab)
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  readThisLogo(inputValue: any): void {
    var file: File = inputValue.files[0];

    this.getBase64(file).then(result => {
      console.log(result.toString().length);
      this.lot4Analysis.image = result.toString();
    });
  }

  getLogo($event) {

    this.readThisLogo($event.target);
    console.log("-|4->", this.filesLogo.nativeElement.files.length);
    console.log("-|4->", this.filesLogo.nativeElement.files[0].name);

  }


}
