import { Component, Inject, OnInit  } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { KcConnectService } from '../providers/kc-connect.service';
import { logged } from '../support/logged';
import { LabProviderService } from '../providers/lab-provider.service';
import { i_Lab } from '../models/Lab';
import { IntecomService } from '../providers/intecom.service';
import { Subscription } from 'rxjs';
import { SelectedLab } from '../models/SelectedLab';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-labselect',
  templateUrl: './labselect.component.html',
  styleUrls: ['./labselect.component.scss']
})
export class LabselectComponent extends logged implements OnInit {

  subscription: Subscription;
  env: any;
  labs: SelectedLab[] = [];


  constructor(protected keycloakService: KeycloakService,
    protected comunication: KcConnectService,
    protected lab_service: LabProviderService,
    private intercom: IntecomService,
    public dialogRef: MatDialogRef<LabselectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: i_Lab[]
  ) {
    super(keycloakService, comunication);
    this.env = environment;

  }



  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit() {
    console.log("Mi tiro su!")
    this.populatelist();

  }



  ngOnDestroy() {
    console.log("Mi spengo")
  }

  populatelist() {
    if (!this.data) {
      this.data = [];
    }
    console.log("-- Data: size->", this.data.length)
    this.lab_service.getLabs().then((value: i_Lab[]) => {
      console.log("SUCCESSO: ", value)
      value.forEach(element => {
        console.log(element)
        let ispresent: boolean = false;
        if (this.data) {
          ispresent = this.data.some((x) => x._id == element._id);
          console.log("--ispresent  -->", ispresent)
        }
        this.labs.push(new SelectedLab(element, ispresent));
      });
    }).catch((e) => {
      console.log("ERROR: ", e)
    })
  }

  logout() {
    this.keycloakService.logout();
  }


  onSelect(lab: SelectedLab) {
    let index = this.labs.findIndex((element) => element.$lab._id == lab.$lab._id)
    console.log("--->index : " + index + "| " + lab.$lab._id)
    if (!this.labs[index].$selected) {
      this.data.push(lab.$lab);
    } else {
      this.data = this.data.filter((x) => x._id != lab.$lab._id)
    }
    this.labs[index].$selected = !this.labs[index].$selected;
  }

}

