import { KcConnectService } from '../providers/kc-connect.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
export class logged {

  public token: any;
  public name: string = "";
  public display: boolean = false;


  constructor(protected keycloakService: KeycloakService, protected comunication: KcConnectService) {
    this.keycloakService.getToken().then((token_value: string) => {
      console.log(token_value)
      this.comunication.loggedIn_data(token_value).then((result: string) => {
        this.token = result;
        try {
          this.name = this.token.user.name;
          if (this.token.user.realm_access.roles.includes(environment.role)) {
            this.display = true;
            console.log("Authorized")
          } else {
            this.display = false;
            console.log("NOT Authorized")
          }
        } catch (e) {
          console.log(e)
          console.log("NOT Authorized2")
        }
      }).catch((e) => {
        console.log('Error :', e);
      });
    })

  }
}


