import { basicInfo_schema } from './../../../../s3qr-bdmanager/models/Basic_info';
import { Basic_info, i_Basic_info } from './Basic_info';

import { UUID } from 'angular2-uuid';


export class Lab implements i_Lab {



  _id: string;
  basic_info: Basic_info;
  desc?: string;
  lastmodified: Date;

  constructor() {
    this.basic_info = new Basic_info()
  }


  public static instanceOfLab(object: any): object is i_Lab {
    if (
      ("basic_info" in object)
      && ("lastmodified" in object)) {
      return true;
    } else {
      return false;
    }
  }

  public validateState() {
    if (!this._id) throw "_ID is missing";
    if (!this.basic_info) throw "Basic_info is missing";
  }

  public static of(o: any): Lab {
    if (this.instanceOfLab(o)) {
      try {

        let a: i_Lab = o as i_Lab;
        let exit = new Lab();
        exit._id = a._id;
        exit.basic_info = Basic_info.of(a.basic_info);
        exit.desc = a.desc;
        exit.lastmodified = a.lastmodified;
        exit.validateState();
        return exit;
      } catch (e) {
        throw Error("[a] Can not parse! :" + JSON.stringify(o));
      }
    } else {
      throw Error("[b] Can not parse! :" + JSON.stringify(o));
    }

  }




}


export interface i_Lab {
  _id: string;
  basic_info: i_Basic_info;
  desc?: string;
  lastmodified: Date;
}
