
import { UUID } from 'angular2-uuid';
import { Basic_info, i_Basic_info } from "./Basic_info";
export class Manufacturer implements i_Manufacturer {


  _id: string;
  basic_info: Basic_info;
  lastmodified: Date;


  constructor() {
    this.basic_info = new Basic_info()
	}



  public static instanceOfManufacturer(object: any): object is i_Manufacturer {
    if (("_id" in object) && ("basic_info" in object) && ("lastmodified" in object)) {
      return true;
    } else {
      return false;
    }
  }

  public  validateState() {

    if (!this.basic_info) throw new Error("Basic_info is missing");
  }


  public static of(o: any): Manufacturer {
    if (this.instanceOfManufacturer(o)) {
      try {
        let a: i_Manufacturer = o as i_Manufacturer;
        let _basic: Basic_info = Basic_info.of(a.basic_info);
        let exit = new Manufacturer()
        exit._id = a._id;
        exit.basic_info = _basic;
        exit.lastmodified = a.lastmodified;
        exit.validateState();
        return exit;
      } catch (e) {
        throw Error("[c] Can not parse! :" + JSON.stringify(o));
      }
    } else {
      throw Error("[d] Can not parse! :" + JSON.stringify(o));
    }
  }





}


export interface i_Manufacturer {

  _id: string;
  basic_info: i_Basic_info;
  lastmodified: Date;

}

