import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { KcConnectService } from '../providers/kc-connect.service';
import { KeycloakService } from 'keycloak-angular';
import { LabProviderService } from '../providers/lab-provider.service';
import { logged } from '../support/logged';
import { environment } from '../../environments/environment';
import { IntecomService } from '../providers/intecom.service';
import { Lab } from '../models/Lab';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Generic_messageComponent } from '../generic_message/generic_message.component';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-labform',
  templateUrl: './labform.component.html',
  styleUrls: ['./labform.component.css']
})
export class LabformComponent extends logged implements OnInit {
  env: any;
  lab: Lab = new Lab();
  registerForm: FormGroup;
  submitted = false;
  subscription: Subscription;
  isEdit: boolean = false;
  @ViewChild("filesLogo") filesLogo: ElementRef;
  @Output() refreshlistlabEvent = new EventEmitter<string>();


  constructor(public dialog: MatDialog, protected keycloakService: KeycloakService,
    protected comunication: KcConnectService,
    protected lab_service: LabProviderService,
    private formBuilder: FormBuilder,
    private intercom: IntecomService) {
    super(keycloakService, comunication);
    this.env = environment;
  }




  sendRefreshEvent() {
    this.refreshlistlabEvent.emit(this.env.refreshlablist);
  }


  ngOnInit(): void {

    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      _id: ['', Validators.required],
      address: ['', Validators.required],
      phone: [],
      website: [],
      desc: [],
      email: ['', [Validators.required, Validators.email]]
    });

    this.subscription = this.intercom.formLabItem$.subscribe((message) => {
      try {
        this.lab = Lab.of(JSON.parse(message))
        this.isEdit = true;
      } catch (e) {
        this.isEdit = false;
        this.lab = new Lab();
        console.error(e)
      }

    });


  }

  addlab() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 400;

    this.lab_service.addLab(this.lab).then((res: boolean) => {
      console.log(res)

      if (res) {

        this.intercom.refreshList(this.env.refreshlablist);
        dialogConfig.data = {
          title: environment.success,
        };
        this.submitted = false;
        this.filesLogo.nativeElement.value = "";
        this.lab = new Lab()
      } else {
        dialogConfig.data = {
          title: environment.error,
        };
      }
      this.dialog.open(Generic_messageComponent, dialogConfig);
    }).catch((err) => {
      console.log(err)
      dialogConfig.data = {
        title: environment.error,
      };
      this.dialog.open(Generic_messageComponent, dialogConfig);
    })
  }
  get f() { return this.registerForm.controls; }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  readThisLogo(inputValue: any): void {
    var file: File = inputValue.files[0];

    this.getBase64(file).then(result => {
      console.log(result.toString().length);
      this.lab.basic_info.logo = result.toString();
    });
  }

  getLogo($event) {

    this.readThisLogo($event.target);
    console.log("-|4->", this.filesLogo.nativeElement.files.length);
    console.log("-|4->", this.filesLogo.nativeElement.files[0].name);

  }
}
