import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';

import { KeycloakService } from 'keycloak-angular';

import { environment } from '../../environments/environment';
import { KcConnectService } from '../providers/kc-connect.service';
import { logged } from '../support/logged';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})


export class MainComponent extends logged implements OnInit {


  result: any = {};
  env: any;
  name: string = "";
  _lots: boolean = false;
  _manufacturer: boolean = false;
  _labs: boolean = false;
  _users: boolean = true;


  constructor(protected keycloakService: KeycloakService, protected comunication: KcConnectService) {
    super(keycloakService, comunication);
    this.env = environment;


  }

  ngOnInit(): void {


  }

  goToLab() {

    this._labs = true;
    this._users = false;
    this._lots = false;
    this._manufacturer = false;
  }

  goToUsers() {
    this._labs = false;
    this._users = true;
    this._lots = false;
    this._manufacturer = false;
  }

  goToManufacturer () {
    this._labs = false;
    this._users = false;
    this._lots = false;
    this._manufacturer = true;
  }

  goToLots() {
    this._labs = false;
    this._users = false;
    this._lots = true;
    this._manufacturer = false;
  }




logout() {
  this.keycloakService.logout();
}

}

