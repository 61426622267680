import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { catchError, timeout } from 'rxjs/operators';

import { throwError } from 'rxjs/internal/observable/throwError';
import { i_Lot4Analysis, Lot4Analysis } from '../models/Lot4Analysis';

@Injectable({
  providedIn: 'root'
})
export class LotProviderService {

  constructor(private http: HttpClient, protected keycloakService: KeycloakService) { }

  public async getLot4Analysis(): Promise<any> {

    return this.http.get(environment.main_url + environment.getLot4Analysis).pipe(timeout(environment.timeout)).toPromise();

  }


  public async addLot4Analysis(l: Lot4Analysis): Promise<any> {
    console.log(l)

    return this.http.post(environment.main_url + environment.addLot4Analysis, l).toPromise();

  }

  public async deleteLot4Analysis(l: i_Lot4Analysis): Promise<any> {
    console.log(l)

    return this.http.delete(environment.main_url + environment.backend_deleteLot4AnalysisById+ l._id).toPromise();

  }



  public async updateLot4Analysis(l: i_Lot4Analysis): Promise<any> {
    console.log(l)

    return this.http.post(environment.main_url + environment.update_Lot, l).toPromise();

  }
}
